import React, { useState } from 'react';
import axios from 'axios';
import './Chatbot.css';

const Chatbot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([
    { sender: "bot", text: "Hello! Ask me anything." }
  ]);
  const [userInput, setUserInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  /*const sendMessage = async () => {
    if (userInput.trim()) {
      // Add the user's message to the chat
      setMessages([...messages, { sender: "user", text: userInput }]);
      const userMessage = userInput;
      setUserInput("");
      setIsLoading(true);

      try {
        // Make the API call to OpenAI
        const response = await axios.post(
          "https://api.openai.com/v1/chat/completions",
          {
            model: "gpt-4o-mini",
            messages: [
              { role: "system", content: "You are a helpful assistant." },
              { role: "user", content: userMessage }
            ],
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer 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", // Replace with your OpenAI API key
            },
          }
        );
        console.log('=> ' + response);

        // Add the AI's response to the chat
        const botMessage = response.data.choices[0].message.content.trim();
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: "bot", text: botMessage }
        ]);
      } catch (error) {

      } finally {
        setIsLoading(false);
      }
    }
  };*/
  const sendMessage = async () => {
    if (userInput.trim()) {
      // Add the user's message to the chat
      setMessages([...messages, { sender: "user", text: userInput }]);
      const userMessage = userInput;
      setUserInput("");
      setIsLoading(true);
  
      try {
        // Make the API call to OpenAI with headers
        const result = await axios.post(
          "https://api.openai.com/v1/chat/completions",
          {
            model: "gpt-3.5-turbo",
            messages: [
              { role: "system", content: "You are a helpful assistant." },
              { role: "user", content: userMessage }
            ],
            temperature: 0.7
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer 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" // Replace with your actual API key
            }
          }
        );
  
        console.log("=> " + JSON.stringify(result.data, null, 2));
  
        // Add the AI's response to the chat
        const botMessage = result?.data?.choices?.[0]?.message?.content?.trim() || "No response received.";
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: "bot", text: botMessage }
        ]);
      } catch (error) {
        console.error("Error communicating with OpenAI API:", error);
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: "bot", text: "Sorry, I couldn't process that. Please try again." }
        ]);
      } finally {
        setIsLoading(false);
      }
    }
  };
  

    return (
      <div className="chatbot-container">
        {/* Chat Icon */}
        <div className="chat-icon" onClick={toggleChat}>
          💬
        </div>

        {/* Chat Window */}
        {isOpen && (
          <div className="chat-window">
            <div className="chat-header">
              <h3>Chatbot</h3>
              <button onClick={toggleChat}>×</button>
            </div>
            <div className="chat-messages">
              {messages.map((message, index) => (
                <div
                  key={index}
                  className={`chat-message ${message.sender}`}
                >
                  {message.text}
                </div>
              ))}
              {isLoading && <div className="chat-message bot">Typing...</div>}
            </div>
            <div className="chat-input">
              <input
                type="text"
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
                placeholder="Type your message..."
              />
              <button onClick={sendMessage} disabled={isLoading}>
                Send
              </button>
            </div>
          </div>
        )}
      </div>
    );
  };

  export default Chatbot;
